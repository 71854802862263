<template>
    <div style="height:100%;">
        <!-- <v-row>
            <v-col cols="12" class="d-flex justify-center pt-0 pb-4" style="position:relative;">
                <div class="d-flex justify-center flex-wrap btnTypeWrapper">
                    <div v-for="(type, typeName) in libraryType" @click="launchLibType(typeName)" v-show="!type.hideInLibrary" :class="' mx-1 btnType '+(libType == typeName ? ' active' : '')" :key="'btn_'+typeName">
                        <div class="mr-2">
                            <v-img contain width="40" :src="`${publicPath}`+'img/'+type.icon"></v-img>
                        </div>
                        {{ $t(typeName)}}
                    </div>
                </div>
                <div class="bg-orange backgroundBtnType"></div>
            </v-col>
        </v-row> -->
        <div style="height:calc(100%)">
            <LibraryComponent v-if="formLib" :libType="libType" :formLib="formLib" @addModel="addModel" @editModel="editModel" @resetChildsModel="resetChildsModel" @duplicateModel="duplicateModel" @deleteModel="deleteModel" @sendTestModel="sendTestModel" :isFromPopup="false" class="pa-4" :key="libKey"/>
        </div>
        
        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" :isFromLibraryPage="true" :referenceForm="form.reference" @refreshData="customRefresh" />

        <v-dialog width="500" v-model="openExportStep1FormDialog">
            <v-card>
                <v-toolbar tile flat dark class="bg-gradient">
                    <v-toolbar-title>{{$t('create_export_model')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined depressed small class="mr-2" @click="openExportStep1FormDialog = false">{{$t('cancelLabel')}}</v-btn>
                    <v-btn outlined depressed small @click="getForm('/library/getForm?formName=EXPORT&postdata[module_source]=' + exportSourceValue + '&postdata[module_target]=' + exportTargetValue); openExportStep1FormDialog = false; ">{{$t('confirmLabel')}}</v-btn>
                </v-toolbar>
                <v-card-text class="pt-6">
                    <v-select :items="exportSources()" :label="$t('export_model_source') + ' *'" filled dense v-model="exportSourceValue">
                        <template v-slot:selection="{ item }">
                            {{$t('export_model_source_' + item)}}
                        </template>
                        <template v-slot:item="{ item }">
                            {{$t('export_model_source_' + item)}}
                        </template>
                    </v-select>
                    <v-select :items="exportTargets()" :label="$t('export_model_target') + ' *'" filled dense v-model="exportTargetValue">
                        <template v-slot:selection="{ item }">
                            {{$t('export_model_target_' + item)}}
                        </template>
                        <template v-slot:item="{ item }">
                            {{$t('export_model_target_' + item)}}
                        </template>
                    </v-select>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog width="500" v-model="openWorkflowStep1FormDialog">
            <v-card>
                <v-toolbar tile flat dark class="bg-gradient">
                    <v-toolbar-title>{{$t('create_workflow_model')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined depressed small class="mr-2" @click="openWorkflowStep1FormDialog = false">{{$t('cancelLabel')}}</v-btn>
                    <v-btn outlined depressed small @click="getForm('/library/getForm?formName=WORKFLOW&postdata[trigger_id]=' + workflowTriggerValue); openWorkflowStep1FormDialog = false; ">{{$t('confirmLabel')}}</v-btn>
                </v-toolbar>
                <v-card-text class="pt-6">
                    <v-select :items="workflowTriggers" :label="$t('workflow_model_trigger') + ' *'" filled dense v-model="workflowTriggerValue"></v-select>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog width="600" v-model="openLPStep1FormDialog">
            <v-card>
                <v-toolbar tile flat dark class="bg-gradient">
                    <v-toolbar-title>{{$t('create_landingpage_model')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined depressed small class="mr-2" @click="openLPStep1FormDialog = false">{{$t('cancelLabel')}}</v-btn>
                    <v-btn outlined depressed small @click="confirmLayoutSelect">{{$t('confirmLabel')}}</v-btn>
                </v-toolbar>
                <v-card-text class="pt-6 ">
                    <div class="layoutSelector">
                        <v-card :ripple="false" v-for="(layout, index) in landingpageLayouts" @click="selectLayout(layout.id)" :key="'layout_' + index" class="mr-4 cardSelect" :class="(landingpageLayoutValue && landingpageLayoutValue == layout.id) ? 'active': ''">
                            <v-card-text>
                                <v-img fluid max-width="200" min-height="100" :src="layout.preview"></v-img>
                                <h4 style="border:0;" class="mt-2 pb-0">{{ layout.name }}</h4>
                                <ul class="mt-2 pl-0">
                                    <li v-for="(feature, index) in layout.features" :key="feature.id" style="list-style:none">
                                        <v-icon v-if="feature" small color="success" class="ml-2">$check_l</v-icon> 
                                        <v-icon v-else small color="error" class="ml-2">$close</v-icon>
                                        <span class="ml-2">{{index}}</span>
                                    </li>
                                </ul>
                                <v-icon small class="iconCheck" v-if="!landingpageLayoutValue || (landingpageLayoutValue && landingpageLayoutValue != layout.id)">$checkboxOff</v-icon>
                                <v-icon small class="iconCheck" color="success" v-if="(landingpageLayoutValue && landingpageLayoutValue == layout.id)">$checkboxOn</v-icon>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div v-if="layoutError" class="error--text mt-2">{{ $t('layoutisrequired') }}</div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <ActionMenusDialogComponent v-if="openOperationtypeStep1FormDialog" :items="operationtypeTypes" ref="actionMenus" @actionForm="openOperationtypeStep2Form" @cancel="openOperationtypeStep1FormDialog = false;" />
        <ConfirmDialogComponent ref="confirmDialog" />
    </div>
</template>

<script>
import getForm from "@/mixins/mixins";
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import GenericDataService from '@/services/GenericDataService';
import ActionMenusDialogComponent from '@/components/dialogs/ActionMenusDialogComponent';
import LibraryComponent from "../../../components/LibraryComponent.vue";
import ConfirmDialogComponent from "@/components/dialogs/ConfirmDialogComponent";

export default {
    name:'LibraryList',
    mixins: [getForm],
    components: {
        FormDialogComponent,
        ActionMenusDialogComponent,
        LibraryComponent,
        ConfirmDialogComponent,
    },
    beforeRouteLeave (to, from, next) {
      this.$store.dispatch('base/SET_SUB_MENU', []);
      this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
      next();
  },
    data() {
        return {
            libKey: 0,
            form: null,
            formLib:null,
            libType: "OPERATIONTYPE",
            openExportStep1FormDialog: false,
            exportSourcesAndTargets: {
                "operation": ["contact", "demand", "rdv"],
                "retroplanning": ["contact", "demand"],
                "contact_list": ["contact", "demand"],
                "segment": ["contact", "demand"],
                "file": ["contact"]
            },
            exportSourceValue: "operation",
            exportTargetValue: "contact",
            openWorkflowStep1FormDialog: false,
            workflowTriggers: [],
            workflowTriggerValue: null,
            openOperationtypeStep1FormDialog: false,
            operationtypeTypes: [
                {
                    "type": "COMMERCIAL",
                    "label": "commercial",
                    "img": "commercial.png",
                    "text1": "menuActionCommercialText1",
                    "text2": "menuActionCommercialText2"
                },
                {
                    "type": "EVENT",
                    "label": "event_party",
                    "img": "event.png",
                    "text1": "menuActionEventText1",
                    "text2": "menuActionEventText2"
                },
                {
                    "type": "MARKETING",
                    "label": "Marketing",
                    "img": "marketing.png",
                    "text1": "menuActionMarketingText1",
                    "text2": "menuActionMarketingText2"
                }
            ],
            operationtypeTypeValue: [],
            openLPStep1FormDialog: false,
            landingpageLayouts: [],
            landingpageLayoutValue: null,
            layoutError:false,
            libraryType:[],
            publicPath: process.env.BASE_URL,
            activeBtn: ""
        }
    },
    created() {
        GenericDataService.getData('library/getLibTypes').then((response) => {
            this.libraryType = response.data.data;
            this.libraryType = Object.fromEntries(
                Object.entries(this.libraryType).filter(([key, value]) => !value.hideInLibrary)
            );

            let keysLib = Object.keys(this.libraryType);
            this.libType = keysLib[0];
            //console.log(keysLib)
            GenericDataService.getData('/operation/getTypesItemsAction').then((response) => {
                this.operationtypeTypes = response.data.data;
            });
            if (this.$route.query.libType) {
                this.libType = this.$route.query.libType;
            }
            GenericDataService.getData('/library/getFilters?libType=' + this.libType).then((response) => {
                this.formLib = response.data.data;
            });
        
            GenericDataService.getData('/landingpage/getGenericLayouts').then((response) => {
                this.landingpageLayouts = response.data.data;
                this.landingpageLayoutValue = this.landingpageLayouts[0].id;
            });

            GenericDataService.getData("workflow/getTriggerList?is_system=true").then((response) => {
                let items = response.data.data;
                for (let item of items) {
                    if(!this.workflowTriggerValue && item.hasOwnProperty("value"))
                        this.workflowTriggerValue = item.value;

                    if (item.hasOwnProperty("text"))
                        item.text = this.$t(item.text);
                    else if(item.hasOwnProperty("header"))
                        item.header = this.$t(item.header);
                    this.workflowTriggers.push(item);
                }
            });
        });
       
    },
    methods: {
        deleteModel(id) {
            GenericDataService.getData('/library/getNbChilds?libType=' + this.libType + '&id=' + id).then((response) => {
                this.deleteModelAsync(id, response.data.data.nb_used);
            });
        },
        async deleteModelAsync(id, nb_used) {
            if (await this.$refs.confirmDialog.open(this.$t("confirmDeleteModel"), (nb_used > 0 ? this.$t("confirmDeleteModelMessageWithImpacts", { var: nb_used }) : this.$t("confirmDeleteModelMessage")))) {
                GenericDataService.getData('/library/delete?libType=' + this.libType + '&id=' + id).then((response) => {
                    this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => { this.$store.dispatch("REMOVE_SNACK") });
                    this.libKey ++
                });
            }
        },
        editModel(id){
            this.getForm('/library/getForm?formName=' + this.libType + '&id=' + id);
        },
        sendTestModel(id) {
            this.getForm('/template/'+id+'/getForm?formName=sendTest'+this.libType);
        },
        resetChildsModel(id) {
            GenericDataService.getData('/library/getNbResetChilds?libType=' + this.libType + '&id=' + id).then((response) => {
                this.resetChildsModelAsync(id, response.data.data.nb_used);
            });
        },
        async resetChildsModelAsync(id, nb_used){
            if (await this.$refs.confirmDialog.open(this.$t("confirmResetChilds"), this.$t("confirmResetChildsMessage", {var: nb_used}))) {
                GenericDataService.getData('/library/resetChilds?libType=' + this.libType + '&id=' + id).then((response) => {
                    this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => { this.$store.dispatch("REMOVE_SNACK") });
                    this.libKey ++
                });
            }
        },
        duplicateModel(id){
            this.getForm('/library/getForm?formName=' + this.libType + '&id=' + id + "&duplicate=true")
        },
        addModel(){
            switch (this.libType) {
                case "EXPORT":
                    this.openExportStep1FormDialog = true;
                    break;
                case "WORKFLOW":
                    this.openWorkflowStep1FormDialog = true;
                    break;
                case "LANDINGPAGE":
                    this.openLPStep1FormDialog = true;
                    break;
                case "OPERATIONTYPE":
                    if(this.operationtypeTypes.length == 1){
                        this.getForm('/library/getForm?formName=OPERATIONTYPE&postdata[type]=' + this.operationtypeTypes[0].type)
                    } else {
                        this.openOperationtypeStep1FormDialog = true;
                    }
                    break;
                default:
                    this.getForm('/library/getForm?formName=' + this.libType)
                    break;
            }
        },
        launchLibType(typeName){
            let params = [];
            params['libType'] = typeName;
            this.$router.replace({ query: params }).catch(err => { });
            history.replaceState({}, null, this.$route.path + '?' + Object.keys(params)
                .map(key => { return (encodeURIComponent(key) + '=' + encodeURIComponent(params[key])); })
                .join('&'));
                
            this.libType = typeName;
            GenericDataService.getData('/library/getFilters?libType='+this.libType).then((response) => {
                this.formLib = response.data.data
                this.libKey ++
            })
        },
        openOperationtypeStep2Form(item) {
            this.getForm('/library/getForm?formName=OPERATIONTYPE&postdata[type]=' + item.type);
            this.openOperationtypeStep1FormDialog = false;
        },
        exportSources() {
            return Object.keys(this.exportSourcesAndTargets);
        },
        exportTargets() {
            return this.exportSourcesAndTargets[this.exportSourceValue];
        },
        confirmLayoutSelect(){
            if(!this.landingpageLayoutValue)
                this.layoutError = true
            else {
                this.getForm('/library/getForm?formName=LANDINGPAGE&postdata[layout_id]=' + this.landingpageLayoutValue); 
                this.openLPStep1FormDialog = false; 
            }
        },
        selectLayout(id){
            if(this.landingpageLayoutValue && this.landingpageLayoutValue == id){
                this.landingpageLayoutValue = null;
            } else {
                this.landingpageLayoutValue = id
                this.layoutError = false
            }
            
            this.$forceUpdate()
        },
        customRefresh(response){
            this.libKey ++
        },
    },
    watch: {
        '$route' (to, from) {
            if(to.query.libType)
                this.launchLibType(to.query.libType);    
        },
    }
}
</script>

<style lang="scss">
    .btnTypeWrapper {
        position:relative;
        max-width: 1000px;
    }
    .btnType {
        position: relative;
        display:flex;
        cursor:pointer;
        align-items: center;
        background-color:#FFF;
        border-radius:10px;
        padding:10px;
        width:125px;
        top:10px;
        z-index: 1;
        margin-top:8px;
        -webkit-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
        -moz-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
        box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
        &.active {
            background: rgb(96,5,255);
            background: -moz-linear-gradient(180deg, rgba(96,5,255,1) 0%, rgba(181,102,255,1) 100%) !important;
            background: -webkit-linear-gradient(180deg, rgba(96,5,255,1) 0%, rgba(181,102,255,1) 100%) !important;
            background: linear-gradient(180deg, rgba(96,5,255,1) 0%, rgba(181,102,255,1) 100%) !important;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6005ff",endColorstr="#b566ff",GradientType=1) !important;
            color:#ffffff;
        }
    }
    .backgroundBtnType {
        width:100%;
        position: absolute;
        height:calc(100%);
        z-index:0;
        top:0;
    }
</style>