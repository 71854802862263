<template>
  <div class="wrapper">
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
  </div>
</template>
<script>
export default {
  name: "Administration",
  data() {
    return {
     
    };
  },
  beforeRouteLeave (to, from, next) {
      this.$store.dispatch('base/SET_SUB_MENU', []);
      this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
      this.operationToDisplay = {};
      next();
  },
  methods: {
    loggedAs() {
      if (this.$store.state.auth.loggedAs) {
        this.$store.dispatch('settings/SET_PARAM', {key: 'loggedAs', data: null}).then((e) => {
          this.$store.dispatch("auth/SET_LOGGED_AS", null).then((e) => {
              this.$store.dispatch('auth/GET_CURRENT_USER', {}, { root: true })
          })
        })
      }else {
        this.$store.dispatch('settings/SET_PARAM', {key: 'loggedAs', data: this.user}).then((e) => {
          this.$store.dispatch("auth/SET_LOGGED_AS", this.user).then((e) => {
              this.$store.dispatch('auth/GET_CURRENT_USER', {}, { root: true })
          })
        })
      } 
    },
  },
  computed: {
    
  },
  mounted() {
  },
};
</script>
