<template>
    <div>
        <v-container fluid class="pa-6 ">
            <DatatableV2 :displayTitle="false" :datatableId="'burp'":flat="false" :url="apiUrl" ref="blacklistListDatatable"/>
        </v-container>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2';

export default {
    name: "BlacklistList",
    components: { DatatableV2 },
    data() {
        return {
            apiUrl: "/blacklist/list"
        }
    },
    methods: {
    },
}
</script>